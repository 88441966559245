<template>
  <div class="post-list-wrap border-dashed">
    <div class="flex post-list-top" v-if="data">
      <img v-if="data.user_avatar" :src="data.user_avatar" class="avatar" />
      <img v-else src="~@/assets/avatar.jpg" class="avatar" />
      <div>
        <div class="flex">
          <div class="user-name">{{ data.user_nickname }}</div>
          <div class="enterprise" v-if="data.user_enterprise_id">
            {{ data.user_enterprise_name }}
          </div>
        </div>
        <div class="post-time">{{ data.post_time }}</div>
      </div>
    </div>
    <div v-if="isComment" class="comment-content">
      <div class="text-black font-20">{{ data.post_title }}</div>
      <div class="flex mt-20" v-if="getImgSrc(data.post_image)">
        <img
          v-for="(item, index) in getImgSrc(data.post_image)"
          :src="item"
          alt=""
          :key="index"
          class="comment-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs } from "@vue/runtime-core";
export default defineComponent({
  name: "PostUser",
  components: {},
  props: {
    data: {
      type: Object,
      default: null
    },
    isComment: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const getImgSrc = e => {
      if (e) {
        return e.split(",");
      } else {
        return null;
      }
    };
    return {
      getImgSrc
    };
  }
});
</script>
<style lang="less" scoped>
.border-dashed {
  border-bottom: 1px dashed rgba(124, 124, 124, 0.6);
}
.post-list-wrap {
  .post-list-top {
    padding: 10px 0;
    img {
      width: 60px;
      height: 60px;
      background-color: #ffffff;
      border-radius: 60px;
      margin-right: 20px;
    }
    .user-name {
      color: #212531;
      font-size: 20px;
    }
    .enterprise {
      color: #212531;
      opacity: 0.6;
      font-size: 14px;
      margin-left: 20px;
      background-color: #f3f5f8;
      border-radius: 11px;
      padding: 0 10px;
    }
    .post-time {
      color: #212531;
      opacity: 0.6;
      font-size: 16px;
      margin-top: 3px;
    }
  }
  .comment-content {
    background-color: #f3f5f8;
    color: #212531;
    padding: 15px 20px;
    width: 1080px;
    box-sizing: border-box;
    margin-left: 80px;
    margin-bottom: 30px;
    .comment-img {
      width: 240px;
      height: 240px;
      background-color: #212531;
      margin: 10px;
    }
  }
}
</style>
