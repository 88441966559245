<template>
  <div class="post page-wrap">
    <a-spin :spinning="loading">
      <a-empty v-if="post.length == 0" description="暂无信息"></a-empty>
      <div v-else class="wrapper post-box">
        <template v-for="(item, index) in post" :key="index">
          <div class="post-list" @click="goTopostDetail(item)">
            <post-user :data="item"></post-user>
            <div class="list-bottom">
              <div class="post-title">{{ item.post_title }}</div>
              <div class="flex flex-between">
                <div class="flex list-left" style="margin-top: 20px">
                  <div class="flex">
                    <HeartOutlined class="icon" />{{ item.post_favour_num }}
                  </div>
                  <div class="flex" style="margin-left: 30px">
                    <MessageOutlined class="icon" />{{ item.child.length }}
                  </div>
                </div>
                <div class="list-right">
                  <CommentOutlined class="icon" />{{ item.post_type_name }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <a-pagination
          v-model:current="page"
          v-model:pageSize="limit"
          :defaultPageSize="16"
          :hideOnSinglePage="true"
          show-size-changer
          :total="total"
          :pageSizeOptions="['15', '30', '60', '120']"
          @showSizeChange="onShowSizeChange"
        />
      </div>
      <div class="publish-btn" @click="sendPost">发布帖子</div>
    </a-spin>
    <post-popup v-model:visible="visible" @success="sendSuccess"></post-popup>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { getPostList } from "@/api/main.js";
import { message } from "ant-design-vue";
import url from "@/utils/url.js";
import { encode } from "js-base64";
import { getUserId, getUserData } from "@/utils/storeData.js";
import {
  HeartOutlined,
  MessageOutlined,
  CommentOutlined
} from "@ant-design/icons-vue";
import PostUser from "./components/postUser.vue";
import PostPopup from "./components/postPopup.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "Post",
  components: {
    HeartOutlined,
    MessageOutlined,
    CommentOutlined,
    PostUser,
    PostPopup
  },
  setup() {
    const pageData = reactive({
      post: [],
      loading: false,
      user_id: computed(() => getUserId()),
      userData: computed(() => getUserData()),
      page: 1,
      limit: 15,
      total: 0,
      visible: false
    });
    const store = useStore();
    const getPostListFun = () => {
      pageData.loading = true;
      console.log("getUserPostLocationId", pageData.getUserPostLocationId);
      console.log("userData", pageData.userData);

      getPostList({
        page: pageData.page,
        limit: pageData.limit,
        park_id: pageData.userData?.user_park_id,
        post_location_id: store.state.user_post_location_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.post = res.data.post;
            pageData.total = res.count;
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    // getPostListFun();
    const goTopostDetail = _item => {
      url.navigateTo("/post/detail", {
        t: encode(`${_item.post_id}`)
      });
    };
    const onShowSizeChange = (_page, _limit) => {
      pageData.page = _page;
      pageData.limit = _limit;
    };
    const sendPost = () => {
      if (pageData.user_id) {
        pageData.visible = true;
      } else {
        url.navigateTo("/login");
      }
    };
    const sendSuccess = () => {
      getPostListFun();
    };
    watch(
      [
        () => pageData.userData.user_park_id,
        () => store.state.user_post_location_id,
        () => pageData.page,
        () => pageData.limit
      ],
      val => {
        getPostListFun();
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      goTopostDetail,
      onShowSizeChange,
      sendPost,
      sendSuccess
    };
  }
});
</script>
<style lang="less" scoped>
.post {
  padding-top: 10px;
  .post-box {
    .post-list {
      width: 100%;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 20px 30px;
      box-shadow: 0px 0px 27px 0px #eeeeee;
      margin-top: 10px;
      cursor: pointer;
      .list-bottom {
        padding: 20px 10px 10px 10px;
        align-items: flex-end;
        .post-title {
          font-size: 20px;
          color: #212531;
        }
        .list-left {
          font-size: 18px;
          color: #212531;
          opacity: 0.6;
        }
        .icon {
          margin-right: 10px;
        }
        .list-right {
          color: #1276cb;
          font-size: 18px;
        }
      }
    }
  }
  .ant-pagination {
    text-align: center;
    margin-top: 30px;
  }
  .publish-btn {
    width: 400px;
    height: 60px;
    line-height: 60px;
    background-color: #1276cb;
    border-radius: 30px;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    margin: 30px auto;
    cursor: pointer;
  }
}
</style>
